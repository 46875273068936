<template>
  <div>
    <CModal
      :show.sync="$store.state.showModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="xl"
      color="dark"
    >
      <CRow v-if="form">
        <CCol col="12">
          <CCard>
            <CCardBody>
              <CForm>
                <!-- Arabic Name -->
                <CRow class="mt-3">
                  <CCol sm="12" md="9">
                    <CInput
                      invalid
                      description=""
                      label="العنوان"
                      horizontal
                      autocomplete="title"
                      v-model.trim="form.title"
                      invalid-feedback="يوجد خطأ في هذا الحقل"
                      :is-valid="!$v.form.title.$anyError"
                    />
                  </CCol>
                </CRow>

                <CRow class="mt-3 mb-5">
                  <CCol sm="12" md="2"> محتوى المقالة </CCol>
                  <CCol sm="12" md="9">
                    <editor
                      apiKey="mln1amd9gx9droz3rcanvhm9dflaur4dv5zn8udyub03yjph"
                      placeholder="اكتب المحتوى هنا"
                      v-model.trim="form.description"
                      :init="{
                        height: 300,
                        menubar: false,
                        image_uploadtab: true,
                        images_upload_url: `${$tinyUploadImage}`,
                        plugins: [
                          'advlist autolink lists link image charmap',
                          'searchreplace visualblocks code fullscreen',
                          'print preview anchor insertdatetime media',
                          'paste code help wordcount table directionality emoticons',
                        ],
                        toolbar:
                          'emoticons | undo redo image | formatselect | table | link charmap searchreplace visualblocks fullscreen anchor media paste | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | ltr rtl | help',
                      }"
                    >
                    </editor>
                    <h6
                      v-if="$v.form.description.$anyError"
                      class="text-danger mt-2"
                    >
                      يوجد خطأ في هذا الحقل
                    </h6>
                  </CCol>
                </CRow>

                <!-- <CRow class="mt-3">
                  <CCol sm="12" md="9">
                    <div role="group" class="form-group form-row">
                      <label class="col-form-label col-sm-3"> الصورة </label>
                      <CCol sm="12" md="6">
                        <VueFileAgent
                          ref="vueFileAgent"
                          :theme="'grid'"
                          :multiple="false"
                          :deletable="true"
                          :meta="true"
                          :accept="'image/*'"
                          :maxSize="'3MB'"
                          :maxFiles="1"
                          :helpText="'اختار صورة او اسحب الى هنا'"
                          :errorText="{
                            type: 'نوع الملف غير صالح . الملفات من نوع صور فقط التي يتم قبولها',
                            size: 'حجم الملف اكبر من ٣ ميجا',
                          }"
                          @select="filesSelected($event)"
                          @beforedelete="onBeforeDelete($event)"
                          @delete="fileDeleted($event)"
                        ></VueFileAgent>
                      </CCol>
                      <CCol sm="12" md="3">
                        <CImg
                          :src="form.image_url"
                          fluid
                          width="400px"
                          height="400px"
                        />
                      </CCol>
                    </div>
                  </CCol>
                </CRow> -->

              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template v-if="form" #header>
        <h6 class="modal-title font-weight-bold">
          تعديل المقالة رقم : {{ form.id }}
        </h6>
        <CButtonClose @click="closeModal" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="closeModal" color="danger">الغاء</CButton>
        <CButton
          :disabled="loading"
          @click="update"
          type="button"
          color="success"
        >
          <CSpinner v-if="loading" color="white" size="sm" />
          <span v-else> <CIcon name="cil-save" /> حفظ </span>
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import {
  required,
  maxLength,
  minLength,
  numeric,
  url,
} from "vuelidate/lib/validators";
export default {
  name: "Edit",
  components: {
    editor: Editor,
  },
  props: ["form"],
  data() {
    return {
      loading: false,
      formErrors: null,
      fileRecords: [],
      fileRecordsForUpload: [],
    };
  },
  validations: {
    form: {
      title: { required, min: minLength(2), max: maxLength(200) },
      description: { required, min: minLength(30) },
      // status: {},
      // image: {},
    },
  },
  created() {},
  methods: {
    closeModal() {
      this.$store.commit("set", ["showModal", false]);
    },
    update() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;

      this.$http
        .put(`/articles/${this.form.id}`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 200) {
            this.$emit("updateDone");
            this.$store.commit("set", ["showModal", false]);
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if ((error.response.data.status == 422)) {
            this.formErrors = error.response.data.error.errors;
          }
        });
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      const reader = new FileReader();
      reader.onloadend = () => {
        this.form.image = reader.result;
      };
      reader.readAsDataURL(validFileRecords[0].file);
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    fileDeleted: function (fileRecord) {
      this.form.image = null;
    },
  },
};
</script>
